/* @flow */

import React from 'react'
// import Link from 'gatsby-link'
import styles from '../layouts/styles.module.css'

import logo from './logos/logo-doplex.png'

const IndexPage = () => (
  <div>

    <section className={ styles.section }>
      <div className={ styles.sectionContent }>
        <img className={ styles.logo } src={ logo } alt='Doplex' />
        <h1 className={ styles.header }>
          { 'We help organizations with accelerated digital transformation and adopting to the age of data.'}
        </h1>

        <form
          action='https://doplex.us17.list-manage.com/subscribe/post?u=b0a3f899d6e31fec04cb6f681&amp;id=5522343ec7'
          method='post'
          className={ styles.mailinglistForm }
          name='mc-embedded-subscribe-form'
          target='_blank'
          noValidate
        >
          <p>To get updates about us please subscribe:</p>
          <input
            type='email'
            name='EMAIL'
            placeholder='Email Address'
            required
          />
          <br />
          <input
            type='text'
            name='b_b0a3f899d6e31fec04cb6f681_5522343ec7'
            tabIndex='-1'
            className={ styles.hiddenInput }
          />
          <input
            type='submit'
            value='Subscribe'
            name='subscribe'
          />
        </form>

      </div>
      <p>Copyright © 2023 Doplex™ Pty Ltd. All rights reserved.</p>
      <p>DOPLEX is a registered trademark.</p>
    </section>

  </div>
)


export default IndexPage
